import React, { useState } from 'react'
import { GoArrowDown } from "react-icons/go";
import { MdOutlineMailOutline } from "react-icons/md";
import axios from "axios"
import Swal from "sweetalert2"

function HeroSection({handleModal}) {

    const [user, setUser] = useState({email:"", userType:"creator"})
    const [loading, setLoading] = useState(false)

    function handleInput(e) {
        const email = e.target.value
        setUser({...user, email:email})
    }

    async function handleSubmit(e){
        e.preventDefault()
        setLoading(true)

        const {email} = user
        try{
            if(!email){
                setLoading(false)
                return Swal.fire({
                    title: "Missing Field",
                    text: "Field empty! Enter email",
                    icon:"warning"
                })
            }
    
            const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ ;
            const test = regex.test(email)
            if(!test){
                setLoading(false)
                return Swal.fire({
                    title: "Invalid email address!",
                    icon:"warning"
                })
            }

            const data = user
            const url = "https://artbokprelaunchbe.onrender.com/v1/waitlists"
            const response = await axios.post(url, data)

            if(response.data.msg === "Email already exist!"){
                setLoading(false)
                return Swal.fire({
                    title: "Email already exists in waitlists!",
                    icon:"warning"
                })
            }

            //Success Modal
            handleModal("show", email)
            setUser({...user, email:""})
            setLoading(false)

        }
        catch(err){
            setLoading(false)
            return Swal.fire({
                title: "Error",
                text: "Something went wrong!",
                icon:"warning"
            })
        }
    }

    return (
        <div className='herosection multiple-backgrounds'>

            <div className='herosection__imageleft' style={{width:"300px"}}>
                <img src="./assets/images/cpbilft.png" alt="" />
            </div>


            <div className='herosection__content'>
                <p className='herosection__content__announcingtext'>Announcing our Launch date soon!</p>

                <h1 className='herosection__content__header'>Introducing ArtBok</h1>

                <p className='herosection__content__text'>
                    Take your Arts and Fashion NFT
                    to the next level with Artbok
                </p>

                <div className='herosection__content__form'>
                    <form action="" onSubmit={handleSubmit}>
                        <div className='herosection__content__form__entry'>
                            <span><MdOutlineMailOutline/></span>
                            <input type="email" value={user.email} placeholder='Enter you email address' required onChange={handleInput}/>
                        </div>

                        <button className='herosection__content__form__submitbtn'>{loading? "processing..." : "Join waitlist"}</button>
                    </form>
                </div>

                <p className='herosection__content__actiontext'>Want Early Access? Join Now</p>
                <a href='#joinwaitlistform' className='herosection__content__actionarrow'><GoArrowDown/></a>
            </div>


            <div className='herosection__imageright' style={{width:"300px"}}>
                <img src="./assets/images/cpbirght.png" alt="" />
            </div>

        </div>
    )
}

export default HeroSection