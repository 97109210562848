import React from 'react'
import { useTheme } from '../context/ThemeProvider'

function Preloader() {
  
  const {theme} = useTheme()

  return (
    <div className='preloader__global'>

        <div>
          {
            theme==="light"?
            <img src="./assets/images/artboklogo-sa-dark.png" alt="" />:
            <img src="./assets/images/artboklogo-sa-light.png" alt="" />
          }
            
        </div>

    </div>
  )
}

export default Preloader