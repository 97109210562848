import React, { useState } from 'react'
import { MdOutlineMailOutline } from "react-icons/md";
import axios from "axios"
import Swal from "sweetalert2"

function UserPageHero({handleModal}) {

    const [user, setUser] = useState({email:"", userType:"user"})
    const [loading, setLoading] = useState(false)

    function handleInput(e) {
        const email = e.target.value
        setUser({...user, email:email})
    }

    async function handleSubmit(e){
        e.preventDefault()
        setLoading(true)

        const {email} = user
        try{
            if(!email){
                setLoading(false)
                return Swal.fire({
                    title: "Missing Field",
                    text: "Field empty! Enter email",
                    icon:"warning"
                })
            }
    
            const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ ;
            const test = regex.test(email)
            if(!test){
                setLoading(false)
                return Swal.fire({
                    title: "Invalid email address!",
                    icon:"warning"
                })
            }

            const data = user
            const url = "https://artbokprelaunchbe.onrender.com/v1/waitlists"
            const response = await axios.post(url, data)

            if(response.data.msg === "Email already exist!"){
                setLoading(false)
                return Swal.fire({
                    title: "Error",
                    text: "Email already exist",
                    icon:"error"
                })
            }

            //Success Modal
            handleModal("show", email)
            setUser({...user, email:""})
            setLoading(false)

        }
        catch(err){
            setLoading(false)
            return Swal.fire({
                title: "Error",
                text: "Something went wrong!",
                icon:"warning"
            })
        }
    }

    return (
        <div className='userspagehero' style={{position:"relative"}}>

            <div className='userspagehero__nftleftimage'>
                <img src="./assets/images/userspage-left-nft.png" alt="userspage-left-nft" style={{width:"100%"}}/>
            </div>


            <div className='userspagehero__herocontent'>
                <div className='text-center'>
                    <p className='userspagehero__herocontent__text'>Anouncing our Launch date soon!</p>
                </div>

                <div className='userspagehero__herocontent__header'>
                    <h1 className='pt-3 text-center'>
                    <p className='userspagehero__herocontent__header__stars' style={{width:"50px"}}><img src="./assets/images/stars.png" alt="" /></p>
                        Introducing ArtBok
                    </h1>
                    <p className='text-center'>Where Art Meets Fashion on the Blockchain!</p>
                </div>

                <div className='mt-5 userspagehero__herocontent__join__form'>
                    <form action="" onSubmit={handleSubmit} className='d-flex justify-content-center align-items-center gap-3'>
                        <div className="ps-3 userspagehero__herocontent__join__form__input">
                            <span className='me-2' style={{fontSize:"1.2rem", fontWeight:"bold" }}><MdOutlineMailOutline/></span>
                            <input type="email" value={user.email} placeholder="Enter email" required onChange={handleInput}/>
                        </div>

                        <button type='submit'>{loading? "processing..." : "Join waitlist"}</button>
                    </form>
                </div>
            </div>


            <div className='userspagehero__nftrightimage'>
                <img src="./assets//images/userspage-right-nft.png" alt="userspage-right-nft"  style={{width:"100%"}} />
            </div>

        </div>
    )
}

export default UserPageHero